import React from 'react';
import Helmet from 'react-helmet';
import useSiteMetadata from './Data/useSiteMetadata';


export default React.memo(
  ({
    author,
    siteUrl,
    datePublished,
    defaultTitle,
    description,
    image,
    isBlogPost,
    // organization,
    title,
    url,
  }) => {

    const { /*locale,*/siteDomain, organization,localBusinessSchema} = useSiteMetadata()
    const baseSchema = [
      {
        '@context': 'http://schema.org',
        '@type': 'WebSite',
        url,
        name: title,
        alternateName: defaultTitle,
        "description": `${localBusinessSchema.description}`,
        
      },
    ];

    const schema = isBlogPost
      ? [
          ...baseSchema,
          {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                item: {
                  '@id': url,
                  name: title,
                  image,
                },
              },
            ],
          },
          {
            '@context': 'http://schema.org',
            '@type': 'BlogPosting',
            url,
            name: title,
            alternateName: defaultTitle,
            headline: title,
            image: {
              '@type': 'ImageObject',
              url: image,
            },
            description,
            author: {
              '@type': 'Person',
              name: author.name,
            },
            publisher: {
              '@type': 'Organization',
              url: organization.url,
              logo: organization.logo,
              name: organization.name,
            },
            mainEntityOfPage: {
              '@type': 'WebSite',
              '@id': siteUrl,
            },
            datePublished,
          },
        ]
      : baseSchema;

      const Logo = {
        "@type": "Organization",
        "url": `${organization.url}`, //`${localBusinessSchema.url}/`,
        "logo": `${localBusinessSchema.url}/${localBusinessSchema.logo}`, //`${websiteUrl}${logo?.imageFile?.childImageSharp?.fixed.src}`,
        "description": `${organization.description}`,
        "telephone": `${organization.phone}`,
      }

      const LocalBusiness = {
        "@type": `${localBusinessSchema.type}`,//"LocalBusiness",
        "image": [
          //`${localBusinessSchema.url}/${localBusinessSchema.logo}`, 
          //`${websiteUrl}${logo?.imageFile?.childImageSharp?.fixed.src}`
          "https://emsculpt-bremen.de/favicons/favicon.png",
          "https://emsculpt-bremen.de/favicons/favicon_48px.png",
          "https://emsculpt-bremen.de/favicons/favicon_96px.png",      
         ],
        "@id": siteDomain,
        "name": `${localBusinessSchema.name}`,
        "address": {
          "@type": "PostalAddress",
          "streetAddress": `${localBusinessSchema.address.streetAddress}`,
          "addressLocality": `${localBusinessSchema.address.addressLocality}`,
          "addressRegion": `${localBusinessSchema.address.addressRegion}`,
          "postalCode": `${localBusinessSchema.address.postalCode}`,
          "addressCountry": `${localBusinessSchema.address.addressCountry}`,
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": `${localBusinessSchema.geo.latitude}`,
          "longitude": `${localBusinessSchema.geo.longitude}`,
        },
        "url": siteDomain,
        "telephone": `${localBusinessSchema.telephone}`,
        "email": `${organization.email}`, //this needs to be updated
         "sameAs": [
          "https://www.facebook.com/estetiese",
          "https://twitter.com/EstetieseBremen",
          "https://www.instagram.com/docestetiese/", 
          ],
        "priceRange":`${localBusinessSchema.addressCountry}`,
        "openingHoursSpecification": [
          {
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": [
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday"
            ],
            "opens": "09:00",
            "closes": "18:00"
          }
        ]
      }
    
      const All = {
        "@context": "http://schema.org",
        "@graph": [
      //    WebSite,
      //    WebPage,
          Logo,
          schema,
          LocalBusiness
        ]
      }
      

    return (
      <Helmet>
        {/* Schema.org tags */}
        <script type="application/ld+json">{JSON.stringify(All)}</script>
      </Helmet>
    );
  },
);
