import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import SchemaOrg from "./SchemaOrg"


const SEO = ({ title, description, image, lang, meta, keywords,isBlogPost,created }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)
  const datePublished = isBlogPost ? {created} : false;

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername,    
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}/${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <React.Fragment>
    <Helmet 
      htmlAttributes={{
        lang,        
        prefix: 'og: http://ogp.me/ns#'
      }}
      title={seo.title} 
      titleTemplate={titleTemplate}
      meta={[
          { name: 'keywords',  content: keywords ? keywords : site.siteMetadata.keywords } ,
          { name: `viewport`, content: `minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=yes`}, 
          { name: 'robots', content: 'index, follow' },
          { name: 'google.site.verification', content: site.siteMetadata.google_site_verification },
          { name: 'msvalidate.01', content: site.siteMetadata.msval },        
          { name: 'geo.region', content: site.siteMetadata.geo_region },
          { name: 'geo.placename', content: site.siteMetadata.geo_placename },
          { name: 'zipcode', content: site.siteMetadata.zipcode },
          { name: 'geo.position', content: site.siteMetadata.geo_position },
          { name: 'ICBM', content: site.siteMetadata.icbm_pos },         
      ].concat(meta)}
      >
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {seo.url && <meta property="og:url" content={seo.url} />}

      {seo.title && <meta property="og:title" content={seo.title} />}

      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}

      {seo.image && <meta property="og:image" content={seo.image} />}

      <meta name="twitter:card" content="summary_large_image" />

      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}

      {seo.title && <meta name="twitter:title" content={seo.title} />}

      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}

      {seo.image && <meta name="twitter:image" content={seo.image} />}
     </Helmet>
            <SchemaOrg
            isBlogPost={isBlogPost}
            url={pathname}
            title={title}
            image={image}
            description={description}
            datePublished={datePublished}
            siteUrl={site.siteMetadata.siteUrl}
            author={site.siteMetadata.author}
            organization={site.siteMetadata.organization}
            defaultTitle={site.siteMetadata.title}
          />     
    
        </React.Fragment>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),  
}

SEO.defaultProps = {
  title: null,
  //description: null,
  image: null,
  lang: `de`,
  meta: [],
  description: ``,
  isBlogPost: false,
  created: null,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        lang: language
        titleTemplate
        defaultDescription: description
        siteUrl: siteUrl
        defaultImage: image
        geo_placename
        geo_position
        geo_region
        google_site_verification
        icbm_pos
        publisher
        msval
        twitterUsername: author
        keywords
   
      }
    }
  }
`
/*         organization {
          name
          url
          dateCompanyFormed
          description
          logo
          street
          postcode
          city
          phone
          fax
          email          
        }     
*/ 